import React, { useState, useEffect } from "react";
import i18n from "../Locales";
// import axios from "axios";
import {
  KNECT_SUBSCRIPTION_URL,
  // KNECT_SUBSCRIPTION_SERVICE_ID,
  KNECT_SUBSCRIPTION_HEADER,
  // TELCO,
  // KNECT_SUBSCRIPTION_URL_VIRGIN,
  // PARTNER_ID,
  // CAMPAIGN_ID,
  getErrorObj,
  getNumbervalidObj,
} from "../Constants";

const Login = (props) => {
  const [code, setcode] = useState("966");
  const [number, setnumber] = useState("");
  const [telco, settelco] = useState("zain");
  const [pin, setpin] = useState("");
  const [showPin, setshowPin] = useState(false);
  const [errMesg, seterrMsg] = useState("");
  const [btnText, setbtnText] = useState(i18n.continue);
  const [userID] = useState(null);
  const [operator, setOperator] = useState(2);

  //let regexString = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;
  let regexString = "";
  let KNECT_SUBSCRIPTION_SERVICE_ID = 0;

  useEffect(() => {
    console.log("000000000000");
    getAllServices(telco);
  }, []);

  const telcoSelection = (telco) => {
    getAllServices(telco);
  };

  const getUser = async (telco, code, number) => {
    seterrMsg("");
    try {
      // If the user exists user is returned other wise user is returned after creating the user. Behaviour of api used below
      // const url = `${DRAMATIME_URL}/api/user/find_sub_by_phone?web_user=1&telco=${telco}&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&phone=${number}`;

      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkUserStatus?msisdn=${`${number}`}`;
      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();
      console.log(respObj.data);

      if (respObj.data.status === 1) {
        localStorage.setItem("number", number);
        props.setUser(respObj.data, true);
        setbtnText(i18n.continue);
      } else {
        seterrMsg(`Sorry something went wrong. Please try again.`);
      }
    } catch (e) {
      console.log("Error in getting user", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.subscribe);
    }
  };

  const checkORCreateUser = async (telco, code, number) => {
    telcoSelection(telco);
    seterrMsg("");
    localStorage.setItem("telco", telco);
    KNECT_SUBSCRIPTION_SERVICE_ID = localStorage.getItem("serviceID");
    if (!number || number.trim().length !== 12 || !validateNumberSA(number)) {
      seterrMsg(i18n.validnumber);
      setshowPin(false);
      setbtnText(i18n.continue);
      return;
    }

    if (!telco) {
      seterrMsg(i18n.selectcarrier);
      setshowPin(false);
      setbtnText(i18n.continue);
      return;
    }

    try {
      console.log(KNECT_SUBSCRIPTION_SERVICE_ID);
      setbtnText(i18n.pleasewait);
      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkSubscriptionStatus?msisdn=${`${number.slice(
        0,
        number.length
      )}`}&serviceId=${KNECT_SUBSCRIPTION_SERVICE_ID}`;

      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();
      console.log("user Status ->", respObj.success, respObj.response_code);

      console.log("----------------");
      console.log(respObj);
      console.log(number);
      console.log("----------------");

      //success message true with code 114 if testing number == 966111111111
      if (number === "966111111111") {
        respObj.success = true;
        respObj.response_code = 114;
      }
      // validation();
      if (
        respObj.success &&
        (respObj.response_code === 114 || respObj.response_code === 110)
      ) {
        console.log(1);
        console.log("1st postion");
        getUser(telco, code, number);
        setnumber(number);
      } else {
        console.log(2);
        console.log("2nd potion");
        sendPin(telco, code, number);
        setnumber(number);
      }
    } catch (e) {
      console.log("Error in check user status", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.continue);
    }
  };

  const sendPin = async (telco, code, number) => {
    localStorage.setItem("telco", telco);
    KNECT_SUBSCRIPTION_SERVICE_ID = localStorage.getItem("serviceID");
    seterrMsg("");
    try {
      // const url = `${SUBSCRIPTION_URL}/telco/${telco}/send_pin?web_user=1&telco=${telco}&project_id=${SUBSCRIPTION_PROJECT_ID}&secret_key=${SUBSCRIPTION_SECRET_KEY}&msisdn=${number}&user_id=${userID}`
      const url = `${KNECT_SUBSCRIPTION_URL}/api/v1/genAuthCode`;
      // let resp = await fetch(url);
      let resp = await fetch(url, {
        method: "POST",
        headers: KNECT_SUBSCRIPTION_HEADER,
        body: JSON.stringify({
          serviceId: KNECT_SUBSCRIPTION_SERVICE_ID,
          msisdn: `${number.slice(0, number.length)}`,
        }),
      });

      let respObj = await resp.json();
      console.log("Send pin resp ->", respObj);
      // if (respObj.status === 1) {
      if (respObj.success) {
        setshowPin(true);
        setbtnText(i18n.subscribe);
      } else {
        seterrMsg(i18n.sendpinerrmesg);
        setshowPin(false);
        setbtnText(i18n.continue);
      }
    } catch (e) {
      console.log("Error in send pin", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.continue);
    }
  };

  const confirmPin = async (telco, code, number, pin, userID) => {
    KNECT_SUBSCRIPTION_SERVICE_ID = localStorage.getItem("serviceID");
    seterrMsg("");
    if (!pin) {
      seterrMsg(i18n.enterpinmsg);
      setshowPin(true);
      setbtnText(i18n.subscribe);
      return;
    }
    try {
      console.log("---------parameters-----------");
      console.log(number, KNECT_SUBSCRIPTION_SERVICE_ID, pin);
      console.log("---------parameters-----------");
      setbtnText(i18n.pleasewait);
      const url = `${KNECT_SUBSCRIPTION_URL}/api/v1/validateAuthCode`;
      // let resp = await fetch(url);
      let resp = await fetch(url, {
        method: "POST",
        headers: KNECT_SUBSCRIPTION_HEADER,
        body: JSON.stringify({
          msisdn: `${number}`,
          serviceId: KNECT_SUBSCRIPTION_SERVICE_ID,
          pinCode: pin,
        }),
      });
      let respObj = await resp.json();
      console.log("confirm pin resp", respObj);
      //   if (respObj.status === 1 && respObj.user.subscribe_status === 1) {
      console.log("---------confirmpin------------------");
      console.log(respObj);
      console.log("---------confirmpin------------------");
      if (respObj.success && respObj.response_code === 0) {
        getUser(telco, code, number);
        // console.log(`Success`);
      } else {
        seterrMsg(
          respObj.response_code === 103
            ? `Invalid Pin. Please Enter a valid Pin.`
            : i18n.confirmpinerrmsg
        );
        setbtnText(i18n.subscribe);
        // setshowPin(false);
      }

      // setbtnText(i18n.subscribe);
    } catch (e) {
      console.log("Error in confirm pin", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.subscribe);
    }
  };

  const validateNumberSA = (number) => {
    //console.log(number);
    if (number.trim().length === 0) {
      return getErrorObj("Please enter Valid number");
    }
    let msisdn = number;

    //by pass if testing number == 966111111111

    if (number !== "966111111111") {
      if (msisdn.substring(0, 7) === "0096605") {
        msisdn = `05${msisdn.substring(7)}`;
      } else if (msisdn.substring(0, 6) === "009665") {
        msisdn = `05${msisdn.substring(6)}`;
      } else if (msisdn.substring(0, 5) === "09665") {
        msisdn = `05${msisdn.substring(5)}`;
      } else if (msisdn.substring(0, 5) === "96605") {
        msisdn = `05${msisdn.substring(5)}`;
      } else if (msisdn.substring(0, 4) === "9665") {
        msisdn = `05${msisdn.substring(4)}`;
      } else if (msisdn.substring(0, 3) === "905") {
        msisdn = `05${msisdn.substring(3)}`;
      } else if (msisdn.substring(0, 2) === "05") {
        msisdn = `05${msisdn.substring(2)}`;
      } else if (msisdn.substring(0, 1) === "5") {
        msisdn = `05${msisdn.substring(1)}`;
      } else {
        return getErrorObj("Please enter Valid number");
      }
    }
    let numero = msisdn.substring(0, 3);
    let initial = msisdn.substring(0, 3);
    let slicemsisdn = msisdn.substring(1, msisdn.length);

    //Check for testing number 966111111111
    if (number !== "966111111111") msisdn = `966${slicemsisdn}`;
    else msisdn = `9${slicemsisdn}`;

    regexString = msisdn;
    //console.log(msisdn);

    if (msisdn.length !== 12) {
      return getErrorObj("Please enter Valid number");
    } else {
      return getNumbervalidObj(initial, numero, msisdn);
    }
  };

  const getAllServices = async (telco) => {
    console.log(telco);
    seterrMsg("");
    try {
      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/getAllServices`;
      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();
      console.log(respObj.success);

      if (respObj.success === true) {
        localStorage.setItem(
          "serviceID",
          respObj.regions[0].services
            .filter((i) => i.operator.includes(telco))
            .map((i) => i.serviceId)[0]
        );

        console.log("KNECT_SUBSCRIPTION_SERVICE_ID----------");
        setOperator(
          respObj.regions[0].services
            .filter((i) => i.operator.includes(telco))
            .map((i) => i.serviceId)[0]
        );
        console.log(
          respObj.regions[0].services
            .filter((i) => i.operator.includes(telco))
            .map((i) => i.serviceId)[0]
        );

        localStorage.setItem(
          "serviceID",
          respObj.regions[0].services
            .filter((i) => i.operator.includes(telco))
            .map((i) => i.serviceId)[0]
        );

        console.log("KNECT_SUBSCRIPTION_SERVICE_ID----------");
        localStorage.setItem("services", respObj.data);
        setbtnText(i18n.continue);
      } else {
        seterrMsg(`Sorry something went wrong. Please try again.`);
      }
    } catch (e) {
      console.log("Error in getting services", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.subscribe);
    }
  };

  // const checkSubscriptionStatus = async (number) => {
  //   seterrMsg("");
  //   try {
  //     let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkSubscriptionStatusMSISDN?msisdn=${`${number}`}&serviceId=${KNECT_SUBSCRIPTION_SERVICE_ID}&productId=2`;

  //     let resp = await fetch(url, {
  //       method: "GET",
  //       headers: KNECT_SUBSCRIPTION_HEADER,
  //     });

  //     let respObj = await resp.json();

  //     console.log("user Status ->", respObj.success, respObj.response_code);

  //     // validation();
  //     if (
  //       respObj.success &&
  //       (respObj.response_code === 114 || respObj.response_code === 110)
  //     ) {
  //       console.log(1);
  //       getUser(telco, code, number);
  //     } else {
  //       console.log(2);
  //       sendPin(telco, code, number);
  //     }
  //   } catch (e) {
  //     console.log("Error in check user status", e.message);
  //     seterrMsg(i18n.somethingwrongmsg);
  //   }
  // };

  // const getNumberFromHeader = async (url) => {
  //   try {
  //     let resp = await fetch(url);
  //     resp = await resp.json();
  //     return resp;
  //   } catch (error) {
  //     console.error("Error in get number from header", error.message);
  //   }
  // };

  // const getValidNumber = async (operator) => {
  //   const url = `http://knect.khaleef.com:9001/api/subscriptions/checkPkOperator`;
  //   return axios.get(url, {
  //     params: {
  //       msisdn: `${operator}`,
  //     },
  //   });
  // };

  // const getIP = async () => {
  //   try {
  //     const URL = `http://www.geoplugin.net/json.gp`;
  //     let resp = await fetch(URL);
  //     resp = await resp.json();
  //     return resp;
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  return (
    <div>
      <div
        className=""
        style={{
          maxWidth: "100%",
          width: "100%",
          margin: "0 auto -3rem",
          backgroundColor: "#1c1d26",
        }}
      >
        <div className="bg-top">
          <div className="bg-bottom">
            <div style={{ maxWidth: "400px", width: "80%", margin: "0 auto" }}>
              {!showPin && (
                <form>
                  <div className="row">
                    <div className="col">
                      <h5 className="subscription-title">
                        {i18n.subscription}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img
                        src="/img-subscription.png"
                        alt=""
                        className="subscription-img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2 formGridState">
                      <img
                        src="/sa.png"
                        width="30"
                        alt=""
                        style={{ paddingTop: "16px" }}
                      />
                    </div>
                    <div className="col-xs-2 formGridState">
                      <select
                        className="dt-select"
                        onChange={(e) => {
                          setcode(e.target.value);
                        }}
                      >
                        <option value="966" defaultValue>
                          966
                        </option>
                      </select>
                    </div>
                    <div className="col formGridAddress1 pl-0">
                      <input
                        className="form-control"
                        placeholder={i18n.mobilenumber}
                        maxLength="9"
                        onChange={(e) => {
                          setnumber(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className=" col form-group carrier pr-0">
                      <select
                        className="dt-select w-100"
                        onChange={(e) => {
                          settelco(e.target.value);
                        }}
                      >
                        <option value="zain"> Zain </option>
                        <option value="stc"> Stc </option>
                        <option value="mobily"> Mobily </option>
                      </select>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  {telco === "zain" && (
                    <div className="row">
                      <div className="col">
                        {operator === 3 && (
                          <p className="text-center">
                            {" "}
                            {i18n.zainpricepoints}{" "}
                          </p>
                        )}

                        {operator === 4 && (
                          <p className="text-center">
                            {" "}
                            {i18n.zainsubpricepoints}{" "}
                          </p>
                        )}

                        {operator === 5 && (
                          <p className="text-center">
                            {" "}
                            {i18n.zaindigitalpricepoints}{" "}
                          </p>
                        )}

                        {operator === 6 && (
                          <p className="text-center">
                            {" "}
                            {i18n.zainweeklypricepoints}{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {telco === "stc" && (
                    <div className="row">
                      <div className="col">
                        <p className="text-center"> {i18n.stcpricepoints} </p>
                      </div>
                    </div>
                  )}
                  {telco === "mobily" && (
                    <div className="row">
                      <div className="col">
                        <p className="text-center">
                          {" "}
                          {i18n.mobilypricepoints}{" "}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          checkORCreateUser(telco, code, code + number);
                        }}
                        className="btn-next btn btn-primary"
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                </form>
              )}

              {showPin && (
                <form>
                  <div className="row">
                    <div className="col">
                      <h5 className="subscription-title">
                        {i18n.subscription}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img
                        src="/img-subscription.png"
                        alt=""
                        className="subscription-img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col ">
                      <p> {i18n.sendpininfo} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col formGridAddress1">
                      <input
                        style={{ width: "100%" }}
                        className="form-control"
                        placeholder={i18n.enterpin}
                        onChange={(e) => {
                          setpin(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          confirmPin(telco, code, number, pin, userID);
                        }}
                        className="btn-next btn btn-primary"
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center mt-5">
                      <p> {i18n.dontrecievepin} </p>
                      <h6
                        onClick={() => {
                          setshowPin(false);
                          setbtnText(i18n.continue);
                        }}
                        className="text-light cursor-pointer"
                      >
                        {" "}
                        {i18n.resendpin} <i className="dt-chevron-right"></i>
                      </h6>
                    </div>
                  </div>
                </form>
              )}
              <div className="text-center mt-3 text-danger text-bold">
                {errMesg}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
